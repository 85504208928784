.BottomBanner {
	padding: 0;
	margin: 0;
	border: 0;
	display: block;
}

.BottomBanner img {
	width: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
}