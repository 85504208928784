*, html {
    margin: 0;
    padding: 0;  
}

body {
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
	font-weight: 300;
	background: #fff;
  padding:0!important; 
  margin:0 !important;
}