$primary-color: #000;

.Photo{
	background: $primary-color;
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}

.Photo img.largephoto {
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.Photo div.titlebar {
	position: absolute;
	z-index: 9;
	top: 0;
	width: 100%;
	padding: 10px;
	text-align: left;
	background: rgba(0,0,0,0.5);
}

 .Photo div.titlebar img {
	height: 50px;
}

