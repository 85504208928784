.GalleryPhoto {
	display: inline;
	border: 0px solid white;
}

.GalleryPhoto img {
	width: 130px;
	height: 130px;
	max-width: 30%;
	object-fit: cover;
	border: 1px solid white;
}