.TopBanner {
	padding: 0;
	margin: 0;
	border: 0;
	display: block;
}

.TopBanner img {
	width: 100%;
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
}